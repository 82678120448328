import { Divider } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

function getIds(items) {
    return items.reduce((acc, item) => {
        if (item.url) {
            // url has a # as first character, remove it to get the raw CSS-id
            acc.push(item.url.slice(1));
        }
        if (item.items) {
            acc.push(...getIds(item.items));
        }
        return acc;
    }, []);
}

function useActiveId(itemIds) {
    const [activeId, setActiveId] = useState(``);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    }
                });
            },
            { rootMargin: `0% 0% -80% 0%` }
        );
        itemIds.forEach((id) => {
            try {
                observer.observe(document.getElementById(id));
            } catch (error) {}
        });
        return () => {
            itemIds.forEach((id) => {
                try {
                    observer.unobserve(document.getElementById(id));
                } catch (error) {}
            });
        };
    }, [itemIds]);
    return activeId;
}

function renderItems(items, activeId) {
    return (
        <ul>
            {items.map((item) => {
                return (
                    <li key={item.url}>
                        <a
                            className={activeId === item.url.slice(1) ? "" : "bp4-text-muted"}
                            href={item.url}
                            style={{
                                fontWeight: activeId === item.url.slice(1) ? "bold" : "normal",
                            }}
                        >
                            {item.title}
                        </a>
                        {item.items && renderItems(item.items, activeId)}
                    </li>
                );
            })}
        </ul>
    );
}
function TableOfContents(props) {
    const idList = getIds(props.items);
    const activeId = useActiveId(idList);
    return (
        <div className="toc">
            <summary>Table of contents:</summary>
            {renderItems(props.items, activeId)}
        </div>
    );
}
export default TableOfContents;
