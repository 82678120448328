import React from "react";
import TableOfContents from "../components/table-of-contents";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { Card, Tag, Divider } from "@blueprintjs/core";
import kebabCase from "lodash/kebabCase";
import { Link, graphql } from "gatsby";
import { SEO, SeoObject } from "../components/seo";

export const BlogPost = ({ data }) => {
    const { tags = [], title, date, slug, excerpt } = data.mdx.frontmatter;
    const { readingTime } = data.mdx.fields;

    let meta: SeoObject = {
        ...data.mdx.frontmatter,
        description: excerpt || data.mdx.excerpt,
        image: `${slug}/og-image.jpg`,
        title: `${title} - jracollins.com`,
    };

    return (
        <Layout>
            <SEO meta={meta} isBlogPost />
            <article className="wrapper row-gap">
                <Card className="blog-post-header">
                    <h1>{title}</h1>
                    <Divider />
                    <div className="blog-post-meta bp4-text-muted">
                        <div className="blog-post-meta-datetime">
                            <div className="blog-post-meta-date">{date}</div>
                            <div>|</div>
                            <div className="blog-post-meta-reading-time">{readingTime.text}</div>
                        </div>
                        <div className="blog-post-card-tags">
                            {tags.map((tag) => {
                                return (
                                    <Tag minimal interactive key={tag}>
                                        <Link to={`/blog/tag/${kebabCase(tag)}/`}>{tag}</Link>
                                    </Tag>
                                );
                            })}
                        </div>
                    </div>
                </Card>

                <div className="full-bleed wrapper row-gap column-gap blog-wrapper">
                    <Card className="content">
                        <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </Card>
                    <div className="blog-post-layout-toc">{data.mdx.tableOfContents.items ? <TableOfContents items={data.mdx.tableOfContents.items} /> : null}</div>
                </div>
            </article>
        </Layout>
    );
};

export default BlogPost;

export const mdxLayoutTemplateQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            body
            tableOfContents
            excerpt(pruneLength: 500)
            frontmatter {
                excerpt
                layout
                slug
                title
                date(formatString: "DD MMMM YYYY")
                relativeDate: date(fromNow: true)
                isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                tags
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;
